@import './Variables.scss';

.judge-container {
    color: black;

    .center-container {
        width: 70%;

        .player-title {
            margin-bottom: 1.4rem;
        }

        .judge-card-container {
            max-width: 45rem;
        }
    }

    .back-btn,
    .next-btn {
        transition: all .3s ease;

        .MuiIcon-root {
            font-size: 4rem;
            color: $base-color;

        }

        &:disabled {
            opacity: 0.3;

            .MuiIcon-root {
                color: gray;
            }
        }
    }

    .back-btn {
        transform: rotate(180deg);
    }

    .judge-btn {
        @include base-btn-alt;
        width: 15.7rem;
        margin-top: 2.1rem;
    }
}